import moment from "moment";

moment.locale("zh-CN");
export const momentJs = moment;
export const momentFmt = (time = moment(), fmt = "l") => {
	let str;
	if (fmt === "l") {
		str = "YYYY-MM-DD HH:mm:ss";
	} else if (fmt === "s") {
		str = "YYYY-MM-DD";
	} else {
		str = fmt;
	}
	if (typeof time === "number") {
		time = time * 1000;
	}
	return time ? moment(time).format(str) : moment().format(str);
};
